import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Find us on `}<a href="https://www.facebook.com/gareez.bd">{`Facebook`}</a>{` `}<br />{` Email us at `}<a><a parentName="p" {...{
          "href": "mailto:info@gareez.com"
        }}>{`info@gareez.com`}</a></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      