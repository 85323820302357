import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Gareez is an online platform to reduce the hassle of Car buying and selling processes. People will find the best values for their car in a short time. Buyers can buy the car that will be inspected by an authorized company. Gareez will provide a one-year free service warranty for your car. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      